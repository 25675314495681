<template>
  <div class="container">
    <img
      src="/dist/css/images/icons/simple_shapes/cross.svg"
      class="popup__close"
      alt="Закрыть"
      @click="closePopup"
    >
    <div
      v-if="isEmptyConfiguration"
      class="description-wrapper"
      v-html="getDescription"
    />
    <template v-else>
      <p class="demo-popup__text">
        По выбранной конфигурации не нашлось компаний.<br>
        Измените критерии поиска или скачайте статичную демо-выгрузку
      </p>
    </template>

    <div class="demo-popup__link-wrapper">
      <img
        class="demo-popup__link-img"
        src="./img/excel.png"
        alt="icon excel"
      >
      <a
        :href="`/demo/${getFile}`"
        download
        class="demo-popup__link"
      >
        Скачайте <b>статическую демо-базу</b> (для примера, по разным рубрикам)
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DemoPopupContainer',

  data() {
    return {
      section: '',
      isEmptyConfiguration: true,
    };
  },

  computed: {
    getFile() {
      switch (this.section) {
        case 'okved':
          return 'export-base_demo_okved.xlsx';
        case 'new':
        case 'reg_date':
          return 'export-base_demo_new_companies.xlsx';
        case 'key_search':
          return 'company_search_by_key_demo.xlsx';
        default:
          return 'export_base_structure.xlsx';
      }
    },

    getDescription() {
      let description = '';

      switch (this.section) {
        case 'okved':
        case 'reg_date':
        case 'new':
          description = `
          <p class="demo-popup__text">
            Демо-выгрузка формируется после выбора необходимых вам городов и кодов ОКВЭД.
          </p>
          <p class="demo-popup__text">
            Выберите необходимые вам города и коды ОКВЭД в конфигураторе и скачайте демо-выгрузку бесплатно.
          </p>
        `;
          return description;
        case 'key_search':
          description = `
          <p class="demo-popup__text">
            Демо-выгрузка формируется после выбора необходимых вам городов и рубрик.
          </p>
        `;
          return description;
        default:
          description = `
          <p class="demo-popup__text">
            Демо-выгрузка формируется после выбора необходимых вам городов и рубрик.
          </p>
          <p class="demo-popup__text">
            Выберите необходимые вам города и рубрики в конфигураторе и скачайте демо-выгрузку бесплатно.
          </p>
        `;
          return description;
      }
    },
  },

  created() {
    const hash = window.location.hash.slice(1);

    if (hash === '') {
      this.section = window.location.pathname.slice(1);
    } else {
      this.section = hash;
    }

    this.initialize();
  },

  methods: {
    ...mapActions(['closePopup']),

    initialize() {
      switch (this.section) {
        case 'okved':
        // eslint-disable-next-line max-len
          this.isEmptyConfiguration = window.vue_egrul_configurator.$store.getters['okvedTree/getSelectedOkveds'].length === 0
            || !window.vue_egrul_configurator.$store.getters['tree/hasSelectedCities'];
          break;
        case 'reg_date':
        case 'new':
          this.isEmptyConfiguration = !window.vue_new_company_configurator.$store.getters['tree/hasSelectedCities'];
          break;
        case 'key_search':
          this.isEmptyConfiguration = !window.vue_key_search_configurator.$store.getters['geoTree/hasSelectedCities'];
          break;
        default:
        // eslint-disable-next-line max-len
          this.isEmptyConfiguration = window.vue_main_base_configurator.$store.getters['rubricsTree/getSelectedSections'].length === 0
            || !window.vue_main_base_configurator.$store.getters['geoTree/hasSelectedCities'];
          break;
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 35px;
  background-color: #fff;
  border-radius: 15px;
  color: black;
  font-size: 16px;
}

.description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.popup__close {
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.demo-popup__text {
  line-height: 1.2;
}

.demo-popup__link-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.demo-popup__link-img {
  width: 40px;
}

.demo-popup__link {
  color: #1b88c1;
  margin: 0;
}
</style>
