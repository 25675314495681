<template>
  <div
    v-if="isOpenPopup"
    class="demo-popup"
    @click="closePopup"
  >
    <DemoPopupContainer />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DemoPopupContainer from '@/demo_popup/components/DemoPopupContainer.vue';

export default {
  name: 'DemoPopup',

  components: {
    DemoPopupContainer,
  },

  computed: {
    ...mapState(['isOpenPopup']),
  },

  methods: {
    ...mapActions(['closePopup']),
  },
};
</script>

<style scoped>
.demo-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
