<template>
  <div>
    <DemoPopup />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DemoPopup from '@/demo_popup/components/DemoPopup.vue';

export default {
  name: 'App',

  components: {
    DemoPopup,
  },

  methods: {
    ...mapActions(['openPopup']),
  },
};
</script>

<style scoped>

</style>
