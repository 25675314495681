import { createStore } from 'vuex';

const store = createStore({
    state: {
        isOpenPopup: false,
    },

    mutations: {
        setOpenPopup(state, value) {
            state.isOpenPopup = value;
        },
    },

    actions: {
        openPopup({ commit }) {
            document.body.style.overflow = 'hidden';
            commit('setOpenPopup', true);
        },

        closePopup({ commit }) {
            document.body.style.overflow = 'auto';
            commit('setOpenPopup', false);
        },
    },
});

export default store;
