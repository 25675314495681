import * as VueSentry from '@sentry/vue';

/*
* Sentry для Vue (см. https://exportbase.sentry.io/projects/expbase_vue/?project=4507016186429440)
*  */
export default function initSentry(appInstance) {
    VueSentry.init({
        app: appInstance,
        dsn: 'https://364478ab7416b07f2a3f99c28b6a25b0@o4507016182300672.ingest.us.sentry.io/4507016186429440',
    });
}
