import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import App from '@/demo_popup/App.vue';
import store from './store/store';

const app = createApp(App);

initSentry(app);

app.use(store);

window.vue_demo_popup = app.mount('#vue_demo_popup');
